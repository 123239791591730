<template>
  <v-row>
    <v-col v-if="items.length == 0">keine Rechnung</v-col>
    <v-col v-for="item in items" :key="item.id" sm="6" cols="12" align-stretch>
      <v-card>
        <v-list subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle
                ><DateValue :value="item.date" />
              </v-list-item-subtitle>
            </v-list-item-content>
            <CurrencyValue :value="item.amount" class="ml-auto" />
          </v-list-item>

          <v-list-item v-if="item.status.code == 'rejected'">
            <v-list-item-content>
              <v-alert type="error" dense>{{
                item.status.description
              }}</v-alert>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon v-if="item.payee.code == 'schoolFund'"
                >mdi-printer</v-icon
              >
              <v-icon v-if="item.payee.code == 'original'"
                >mdi-credit-card</v-icon
              >
              <v-icon v-if="item.payee.code == 'employee'">mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.event }}
              </v-list-item-title>

              <v-list-item-subtitle
                >{{ item.payee.description }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon v-if="item.account.id == 1">mdi-book-open-variant</v-icon>
              <v-icon v-if="item.account.id == 2">mdi-forest</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.description }}
              </v-list-item-title>

              <v-list-item-subtitle
                >{{ item.account.description }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider />
        <v-card-actions>
          <v-btn
            text
            block
            color="info"
            :to="{
              name: 'InvoiceDetails',
              params: { id: item.id, page: page },
            }"
            >Details {{ item.number }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { defineComponent } from "vue";
import CurrencyValue from "@/components/CurrencyValue.vue";
import DateValue from "common/components/DateValue.vue";

export default defineComponent({
  components: { CurrencyValue, DateValue },
  props: {
    page: { type: String, default: "InvoicePending" },
    items: { type: Array },
  },
});
</script>
